<template>
  <b-container>
    <b-row class="mb-5 p-3">
      <b-col cols="12" class="mb-4 pt-4 text-initial"><curva-title :title="$t('mainNav.hiring')" /></b-col>
      <b-col lg="12" v-for="(form, key) in allForms" :key="key">
        <div class="iq-card iq-style4 p-4 cursor-pointer"
             @click="$router.push({name: 'hiringDetails', params: {'id': form.id}})">
          <b-row>
            <b-col lg="9">
              <p class="blog-title text-initial font-size-18 m-0 p-0 text-warning">{{form.job}}</p>
              <p class="text-initial text-secondary"
                 v-html="`${form.description.length > 120 ? form.description.slice(0,120) + '...' : form.description}`">
              </p>
              <p class="blog-title text-initial font-size-16 m-0 p-0 text-warning cursor-pointer">
                {{ $t('hiring.more') }}</p>
<!--              <div class="d-inline-flex text-initial justify-content-start gap_3 font-size-14">
                <p><i class="text-warning las la-map-marker-alt mr-2"></i>{{ $t('hiring.location') }}</p>
                <p><i class="text-warning las la-clock mr-2"></i>{{ $t('hiring.time') }}</p>
              </div>-->
            </b-col>
            <!--<b-col lg="3" class="d-flex justify-content-center">
              <img class="mini-map" :src="form.gps"/>
            </b-col> -->
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import contactUsService from '../services/contact-us.services'
export default {
  data () {
    return {
      allForms: []
    }
  },
  methods: {
    getAllForms () {
      contactUsService.getHiringData().then(res => {
        this.allForms = res.data.data
      })
    }
  },
  created () {
    this.getAllForms()
  }
}
</script>
